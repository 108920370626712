import React from 'react';
import { useTranslation } from 'react-i18next';
import { TableRow } from '../../../table/row/table-row';
import { TableCell } from '../../../table/cell/table-cell';
import { Text } from '../../../text/text';
import { TableFoot } from '../../../table/foot/table-foot';
import { ValueAmount } from '../../../value/amount/value-amount';
import { BreakpointSizeType } from '../../../../../theme/type/breakpoints-type';
import { UnrealizedAllocationsOverviewMobxDto } from '../../../../../models/mobx/dtos/statistic/unrealized-allocations-overview-mobx-dto';

interface AllocationsUnrealizedOverviewProps {
    overview: UnrealizedAllocationsOverviewMobxDto;
    mobileBreakUnder?: BreakpointSizeType;
}

export const AllocationsUnrealizedOverview: React.FC<AllocationsUnrealizedOverviewProps> = ({
    overview,
    mobileBreakUnder,
}) => {
    const { t } = useTranslation();

    return (
        <TableFoot mobileBreakUnder={mobileBreakUnder}>
            <TableRow mobileBreakUnder={mobileBreakUnder} borderTop borderBottom={false}>
                <TableCell mobileBreakUnder={mobileBreakUnder} key="total">
                    <Text variant={'gray'} align="left" bold uppercase>
                        {t('layout.tables.unrealizedAllocations.total')}
                    </Text>
                </TableCell>
                <TableCell
                    mobileBreakUnder={mobileBreakUnder}
                    key="invested"
                    label={t('layout.tables.unrealizedAllocations.qty')}
                >
                    <Text variant={'secondary'} bold>
                        <ValueAmount amount={overview.invested} currency={overview.currency} mode="pure" hidable />
                    </Text>
                </TableCell>
                <TableCell
                    mobileBreakUnder={mobileBreakUnder}
                    key="value"
                    label={t('layout.tables.unrealizedAllocations.value')}
                >
                    <Text variant={'secondary'} bold>
                        <ValueAmount amount={overview.value} currency={overview.currency} mode="pure" hidable />
                    </Text>
                </TableCell>
                <TableCell
                    mobileBreakUnder={mobileBreakUnder}
                    key="currencyImpactValue"
                    label={t('layout.tables.performanceOfIndividualAssets.taxFree')}
                >
                    <Text variant={overview.taxFreeValue > 0 ? 'positive' : 'negative'} bold>
                        <ValueAmount amount={overview.taxFreeValue} currency={overview.currency} mode="pure" hidable />
                    </Text>
                </TableCell>
                <TableCell
                    mobileBreakUnder={mobileBreakUnder}
                    key="currencyImpactValue"
                    label={t('layout.tables.performanceOfIndividualAssets.currencyImpact')}
                    align="right"
                >
                    <Text align="right" variant={overview.currencyImpactValue > 0 ? 'positive' : 'negative'} bold>
                        <ValueAmount
                            amount={overview.currencyImpactValue}
                            currency={overview.currency}
                            mode="pure"
                            hidable
                        />
                    </Text>
                </TableCell>
                <TableCell
                    mobileBreakUnder={mobileBreakUnder}
                    key="dividendsValue"
                    label={t('layout.tables.unrealizedAllocations.dividendsValue')}
                    align="right"
                >
                    <Text variant={'positive'} bold>
                        <ValueAmount
                            amount={overview.dividendsValue}
                            currency={overview.currency}
                            mode="pure"
                            hidable
                        />
                    </Text>
                </TableCell>
                <TableCell
                    mobileBreakUnder={mobileBreakUnder}
                    key="profit"
                    label={t('layout.tables.unrealizedAllocations.profit')}
                    align="right"
                >
                    <Text align="right" variant={overview.profit > 0 ? 'positive' : 'negative'} bold>
                        <ValueAmount amount={overview.profit} currency={overview.currency} mode="pure" hidable />
                    </Text>
                </TableCell>
            </TableRow>
        </TableFoot>
    );
};
