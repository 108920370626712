import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { createPortal } from 'react-dom';
import { useEffect, useMemo, useState } from 'react';
import { TabEnum } from '../../../../models/enums/tab-enum';
import {
    Header,
    TabsMenuWrapper,
    FullScreen,
    FullScreenTrigger,
    FullScreenHeader,
} from './dashboard-profit-chart.styled';
import { useRootStore } from '../../../../providers/root-store-provider';
import { DashboardProfitChartAbsolute } from './absolute/dashboard-profit-chart-absolute';
import { DashboardProfitChartPercentage } from './percentage/dashboard-profit-chart-percentage';
import { Section } from '../../../../components/app/section/section';
import { TabsMenu } from '../../../../components/app/tabs/menu/tabs-menu';
import { TabsPanel } from '../../../../components/app/tabs/pane/tabs-panel';
import { PeriodSwitcher } from '../../../../components/app/period-switcher/period-switcher';
import { StatisticsIntervalTypeEnum } from '../../../../models/enums/statistics-interval-type-enum';
import { IconClose } from '../../../../components/app/icons/close';
import { PageHeadline } from '../../../../components/app/page-headline/page-headline';

export const DashboardProfitChart: React.FC = observer(() => {
    const { t } = useTranslation();
    const rootStore = useRootStore();
    const { statisticStore, portfolioStore } = rootStore;
    const { profitStore } = statisticStore;
    const [isFullScreen, setIsFullScreen] = useState(false);

    const toggleFullScreen = () => {
        setIsFullScreen(!isFullScreen);
    };

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === 'Escape' && setIsFullScreen) {
                setIsFullScreen(false);
            }
        };

        if (isFullScreen) {
            document.addEventListener('keydown', handleKeyDown);
            document.body.style.overflow = 'hidden';
        } else {
            document.removeEventListener('keydown', handleKeyDown);
            document.body.style.overflow = '';
        }
    }, [isFullScreen, setIsFullScreen]);

    const handleChangeTab = async (tab: TabEnum) => {
        profitStore.setTab(tab);
        profitStore.filterSelected();
    };

    const tabsMenu = [
        {
            label: t('pages.index.graphTitle.totalValue'),
            value: TabEnum.Absolute,
        },
        {
            label: t('pages.index.graphTitle.percentagePerformance'),
            value: TabEnum.Percentage,
        },
    ];

    const memoProfitChart = useMemo(() => {
        return (
            <div>
                {statisticStore.type !== StatisticsIntervalTypeEnum.Today ? (
                    <>
                        <Header>
                            <TabsMenuWrapper>
                                <TabsMenu onChange={handleChangeTab} value={profitStore.tab} items={tabsMenu} />
                            </TabsMenuWrapper>
                        </Header>
                        <TabsPanel value={TabEnum.Absolute} activeTab={profitStore.tab}>
                            <DashboardProfitChartAbsolute
                                toggleFullScreen={toggleFullScreen}
                                isFullScreen={isFullScreen}
                            />
                        </TabsPanel>
                        <TabsPanel value={TabEnum.Percentage} activeTab={profitStore.tab}>
                            <DashboardProfitChartPercentage
                                toggleFullScreen={toggleFullScreen}
                                isFullScreen={isFullScreen}
                            />
                        </TabsPanel>
                    </>
                ) : null}
            </div>
        );
    }, [profitStore.tab, isFullScreen, statisticStore.type]);

    const renderFullScreenChart = () => (
        <FullScreen>
            <FullScreenTrigger onClick={toggleFullScreen}>
                <IconClose />
            </FullScreenTrigger>
            <FullScreenHeader>
                <PageHeadline title={t('pages.index.graphHeadline')} action={<PeriodSwitcher />} />
            </FullScreenHeader>
            {memoProfitChart}
        </FullScreen>
    );

    return (
        <>
            <Section
                title={t('pages.index.graphHeadline')}
                action={portfolioStore.selectedPortfolio.numberOfAssets === 0 ? <></> : <PeriodSwitcher />}
            >
                {memoProfitChart}
            </Section>
            {isFullScreen && createPortal(renderFullScreenChart(), document.body)}
        </>
    );
});
