import styled, { css } from 'styled-components';

export const GridItem = styled.div`
    ${({ theme }) => css`
        display: flex;
        flex-direction: column;
        border-radius: ${theme.radius.large};
        padding: ${theme.gap.medium};
        border: 0.1rem solid ${theme.card.variant.default.border};
        height: 100%;

        & > * {
            height: 100%;
        }

        @media ${theme.breakpoints.vl.min} and ${theme.breakpoints.xl.max} {
            padding: ${theme.gap.vsmall};
        }
    `}
`;

export const SectionWrapper = styled.div`
    ${({ theme }) => css`
        margin-top: ${theme.gap.small};
        margin-bottom: ${theme.gap.xlarge};
    `}
`;
