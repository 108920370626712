import styled, { css } from 'styled-components';

export const Header = styled.div(
    ({ theme }) => css`
        @media ${theme.breakpoints.vl.min} {
            display: inline-flex;
            align-items: baseline;
        }
    `,
);

export const TabsMenuWrapper = styled.div(
    ({ theme }) => css`
        @media ${theme.breakpoints.vl.max} {
            margin-top: ${theme.gap.small};
            margin-bottom: ${theme.gap.small};
        }
    `,
);

export const FullScreen = styled.div(
    ({ theme }) => css`
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background-color: ${theme.palette.background.body};
        z-index: 9999;
        padding: 2rem 2rem 2rem;
    `,
);

export const FullScreenHeader = styled.div`
    padding-right: 6rem;
`;

export const FullScreenTrigger = styled.div(
    ({ theme }) => css`
        position: absolute;
        top: 2rem;
        right: 2rem;
        width: 4rem;
        height: 4rem;
        display: none;
        align-items: center;
        justify-content: center;
        border-radius: 100%;
        z-index: 1000;
        cursor: pointer;
        background-color: ${theme.palette.color.secondary.opacity.low};
        transition: all ${theme.base.transition};

        @media ${theme.breakpoints.vl.min} {
            display: flex;
        }

        &:hover {
            background-color: ${theme.palette.color.secondary.main};
        }

        i {
            color: ${theme.palette.common.textInverse};
        }
    `,
);
