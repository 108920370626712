import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';
import { useRootStore } from '../../../../providers/root-store-provider';
import { NoData } from '../../../../components/app/no-data/no-data';
import { routes } from '../../../../config/routes';

export const DashboardNoData: React.FC = observer(() => {
    const { t } = useTranslation();
    const { tradeStore, currencyStore } = useRootStore();
    const { tradeDialogStore } = tradeStore;
    const router = useRouter();

    useEffect(() => {
        currencyStore.fetchCurrencyList();
    });

    return (
        <NoData
            actionOnClick={() => {
                router.push(routes.app.trades);
                tradeDialogStore.openCreateDialog();
                tradeDialogStore.fetchSymbols();
            }}
            actionLabel={t('pages.trades.addTrade')}
        >
            {t('layout.tables.noData')}
        </NoData>
    );
});
